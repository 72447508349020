* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.btn.enabled {
  color: #fff;
  border-color: #008cba;
  background-color: #008cba;
}

.btn:disabled,
.btn.disabled {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
.btn:disabled:hover,
.btn:disabled:focus,
.btn.disabled:hover,
.btn.disabled:focus {
  color: #fff;
  border-color: #a0a0a0;
  background-color: #a0a0a0;
}
