button:focus {
  outline: 1px dotted;
  outline: none !important;
}
#c-label label {
  float: left;
}
body .p-button-info {
  background-color: rgb(22, 111, 138) !important;
  border: 1px solid rgb(22, 111, 138) !important;
}
.smartphone {
  position: relative;
  width: 100%;
  max-width: 240px;
  max-height: 430px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: "";
  display: block;
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  bottom: -55px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 35%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 100%;
  max-width: 210px;
  height: 333px;
  background: url("../assets/theme-bg.png");
  margin-top: -8px;
  border-radius: 10px;
  left: -19px;
  /* margin-left: -3px; */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background-color: #464242;
  overflow: auto;
}
.headerbg {
  background-color: #166a8f;
  height: 45px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  margin-top: 5px;
}
.headercontent {
  width: 99%;
  display: block;
  overflow-wrap: break-word;
  padding: 2px;
}
.lockheadercontent {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
.headertitle {
  padding: 2px;
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;
}
.locktitle {
  padding: 2px;
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;
}
.headermsg {
  padding: 2px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin: 0px;
}
.lockmsg {
  padding: 2px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin: 0px;
}
.brandimg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
}
.contentgird {
  overflow-y: scroll;
  height: 510px;
}
.app_title {
  color: #ffffff;
  font-size: 7px;
  text-align: center;
  padding: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.smartphone-theme {
  position: relative;
  width: 125px;
  height: 260px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 30px;
  border-bottom-width: 30px;
  border-radius: 15px;
}

/* The horizontal line on the top of the device */
.smartphone-theme:before {
  content: "";
  display: block;
  width: 40px;
  height: 5px;
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone-theme:after {
  content: "";
  display: block;
  width: 35px;
  height: 16px;
  position: absolute;
  left: 50%;
  bottom: -33px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 35%;
}

/* The screen (or content) of the device */
.smartphone-theme .content {
  width: 100px;
  height: 220px;
  background: url("../assets/theme-bg.png");
  margin-top: -8px;
  border-radius: 10px;
  left: -19px;
  margin-left: -3px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.theme-headerbg {
  background-color: #166a8f;
  height: 30px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  margin-top: -15px;
}
.device-name {
  color: #fff;
  font-size: 10px;
  padding-left: 5px;
}
.theme-icon {
  padding: 2px;
  width: 23px;
}
.bgcolor {
  background: #166a8f;
  padding: 7px;
  border-radius: 12px;
  width: 100%;
  max-width: 260px;
  /* width: fit-content; */
}
body .p-inputtext {
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -webkit-transition: border-color 0.2s, box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  width: 98%;
}
body .p-dropdown {
  background: #ffffff;
  border: 1px solid #a6a6a6;
  -webkit-transition: border-color 0.2s, box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  width: 98%;
}
.app-icon {
  display: inline-block;
  height: 60px;
  background: transparent;
  margin: 10px;
  width: 99%;
  border: 1px solid rgba(7, 0, 0, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  box-sizing: border-box;
  border-radius: 10px;
}
.icon-title {
  padding-left: 75px;
  font-weight: bold;
  display: block;
  margin-top: -50px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.icon-des {
  display: block;
  padding-left: 75px;
}
.app-img {
  margin-left: -1px;
  border-radius: 50px;
}
.icon-list {
  display: inline-block;
  height: 60px;
  background: transparent;
  margin: 10px;
  width: 99%;
  border: 1px solid rgba(7, 0, 0, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  box-sizing: border-box;
  border-radius: 10px;
}
.a-list {
  display: inline-block;
  height: 60px;
  margin: 10px 0px;
  min-width: 99%;
  background: transparent;
  border: 1px solid rgba(7, 0, 0, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  box-sizing: border-box;
  border-radius: 10px;
}
.a-list2 {
  display: inline-block;
  height: 70px;
  margin: 10px 0px;
  min-width: 99%;
  max-width: 99%;
  background: transparent;
  border: 1px solid rgba(7, 0, 0, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  box-sizing: border-box;
  border-radius: 10px;
}
.list-blcok {
  background: #166a8f;
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
}
.list-blcok2 {
  background: #166a8f;
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 7px;
}
.list-title {
  padding-left: 65px;
  font-weight: bold;
  display: block;
  margin-top: -50px;
}
.list-title2 {
  padding-left: 65px;
  display: block;
  font-size: 11px;
}
.list-img {
  display: block;
}
.list-link {
  padding-left: 65px;
}
.a-list a {
  color: #000000;
  text-decoration: underline;
  font-size: 12px;
}
.assign-list {
  display: inline-block;
  height: 80px;
  margin: 10px 0px;
  min-width: 320px;
  background: transparent;
  border: none;
  border-radius: 10px;
}
.assign-blcok {
  background: #166a8f;
  height: 70px;
  width: 70px;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
}
.assign-title {
  padding-left: 90px;
  font-weight: bold;
  display: block;
  font-size: 16px;
  margin-top: -70px;
}
.assign-subtitle {
  padding-left: 90px;
  font-weight: normal;
  display: block;
}
.assign-img {
  display: block;
}
.assign-link {
  padding-left: 90px;
}
.assign-list a {
  color: #000000;
  text-decoration: underline;
  font-size: 12px;
}
a .active {
  background: #efefef !important;
}
.device-list {
  display: inline-block;
  height: 170px;
  margin: 10px 0px;
  min-width: 99%;
  max-width: 99%;
  background: transparent;
  border-radius: 10px;
  border: 1px solid gray;
}
/* .device-imgblcok {
  background: transparent;
  width: 25%;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
  display: inline-block;
} */
.device-imgblcok {
  background: transparent;
  width: 15%;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 35px;
  display: inline-block;
}
/* .device-infoblcok {
  background: transparent;
  width: 50%;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
  display: inline-block;
} */
.device-infoblcok {
  background: transparent;
  width: 70%;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
  display: inline-block;
}
/* .info-title {
  padding-left: 10px;
  font-weight: normal;
  display: block;
} */
.info-title {
  padding-left: 10px;
  font-weight: normal;
  display: block;
  width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.info-span {
  width: 190px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.device-img {
  display: block;
}
.device-link {
  padding-left: 10px;
  text-align: center;
}
/* .device-check {
  float: right;
  width: 22%;
} */
.device-check {
  float: right;
  width: 10%;
}
.dblock-list {
  display: inline-block;
  padding: 5px;
  min-width: 99%;
  background: transparent;
  border-radius: 10px;
  border: none;
}
.dblock-imgblcok {
  background: transparent;
  width: 25%;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 4px;
  display: inline-block;
}
.dblock-infoblcok {
  background: transparent;
  width: 90%;
  padding: 5px;
  margin-left: 5px;
  margin-top: 4px;
  display: inline-block;
}
/* hover style just for information */
label:hover:before {
  border: 2px solid #4778d9 !important;
}

#cuscheck input[type="checkbox"] {
  display: none;
}

#cuscheck input[type="checkbox"]:checked {
  background: #d2d6d2 !important;
  float: right;
  margin-top: -10px;
  margin-right: 15px;
  display: block;
}
#cuscheck .app-icon {
  min-width: 95% !important;
  max-width: 95% !important;
  height: 57px !important;
}
.layout-topbar {
  position: absolute !important;
  height: 70px !important;
  padding: 1.5em 1.5em 0em 1.5em !important;
  color: #ffffff;
  z-index: 999;
  right: 0;
  background: #2dbe60 !important;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2dbe60),
    to(#2dbe60)
  ) !important;
  background: linear-gradient(to right, #2dbe60 0%, #2dbe60 100%) !important;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}
.layout-topbar2 {
  position: absolute;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  width: 100%;
  padding: 0.7em 1.5em 0em 1.5em;
  color: #ffffff;
  z-index: -1;
  right: 0;
  background-image: url(../assets/header.png);
  background-position: 0px -117px;
}
body .p-breadcrumb {
  margin: 35px 0px 20px 0px;
  padding-left: 0px !important;
}

body .p-dataview .p-dataview-header {
  background-color: transparent !important;
  color: #333333;
  border: none !important;
  padding: 5px 0px 20px 0px !important;
}
body .p-breadcrumb {
  background-color: transparent !important;
  border: none !important;
}
body .p-breadcrumb ul li:first-child a {
  color: #f8f9fa !important;
}
body .p-breadcrumb ul li .p-menuitem-link {
  color: #ffffff !important;
}
body .p-breadcrumb ul li.p-breadcrumb-chevron {
  color: #ffffff !important;
  margin: 0 0.1em 0 0.1em !important;
}
.layout-sidebar-dark {
  background: #40767f !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#40767f),
    to(#3a7e8a)
  ) !important;
  background: linear-gradient(to bottom, #40767f 0%, #3a7e8a 100%) !important;
}
.layout-sidebar-dark .layout-menu > li > a {
  border-top: 1px solid #f8f9fa !important;
}
.layout-sidebar-dark .layout-menu > li:last-child > a {
  border-bottom: 1px solid #f8f9fa !important;
}
.layout-sidebar .layout-logo {
  margin-top: 10px !important;
}

.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 0px;
}
.small-box > .inner {
  padding: 10px;
}
.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0px 0px 9px 9px;
}
.bg-aqua {
  height: 170px;
  background: rgb(28, 130, 129);
  background: linear-gradient(
    193deg,
    rgba(28, 130, 129, 1) 0%,
    rgba(22, 106, 143, 1) 50%,
    rgba(28, 130, 129, 1) 100%,
    rgba(22, 106, 143, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  width: 100%;
  border-radius: 10px;
}
.icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 42px 25px;
}
.feature-img {
  height: 60px;
  width: 60px;
}
.layout-main {
  padding: 50px 16px 16px 16px !important;
}
.layout-sidebar-dark .layout-menu li a:hover {
  color: #ffffff !important;
}
.bg-col {
  background: #fff;
  padding-top: 15px;
  border-radius: 10px;
}
.bg-col2 {
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
}
body .p-datatable .p-datatable-thead > tr > th {
  color: #f8f9fa !important;
  background-color: #24b055 !important;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #f8f9fa !important;
}
.dzu-dropzone {
  min-height: 285px !important;
  overflow: hidden !important;
  border: none !important;
  background-color: #fbfbfb;
}
.p-z {
  padding: 2px !important;
}
.p-zz {
  padding: 0px !important;
}
#c-grid .p-zz {
  padding: 5px !important;
}
.p-ll {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  color: #fff !important;
}
.btn {
  padding: 0.575rem 0.75rem !important;
  border-radius: 1.25rem !important;
}
.btn2 {
  float: right;
  background: rgb(22, 111, 138);
  padding: 5px 25px;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  border: none;
}
.btn2:focus {
  outline: none;
}
.tablelink {
  padding: 2px 5px;
  text-decoration: underline;
  color: #000000;
}
.btn3 {
  padding: 1px 12px;
  margin: 5px;
  font-size: 12px;
  border: none;
  color: #ffffff;
  border-radius: 10px;
}
.btn4 {
  background: transparent;
  border: 1px solid #ffffff;
  padding: 2px 5px;
  color: #fff;
  border-radius: 15px;
  margin-left: 10px;
}
.btn5 {
  float: right;
  background: rgb(22, 111, 138);
  margin-left: 40px;
  padding: 6px 34px;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  border: none;
}
.btn6 {
  background: rgb(22, 111, 138);
  padding: 6px 34px;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  border: none;
}
.btn7 {
  background: #dc3545;
  padding: 6px 34px;
  color: rgb(255, 255, 255);
  border-radius: 20px;
  border: none;
}
.btn3 .btn4 .btn5:focus {
  outline: none;
}
.jDAmNq {
  grid-template-columns: 150px 50px 150px !important;
}
.dsCbaN {
  padding: 15px !important;
}
.ivJsWz {
  z-index: 999;
}
.cbEiuo {
  padding: 0 35px !important;
}
.time-line-ctnr {
  max-height: 255px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.time-line-ctnr .time-line:before {
  background: #c4c8cc !important;
}
.time-line-ctnr .time-line > li > .fa {
  background: #2dbe60 !important;
  border: 2px solid #2dbe60 !important;
}
.time-line-ctnr .time-line > .time-label > span {
  background-color: #2dbe60 !important;
}
.time-line-ctnr .time-line > li > .time-line-item .time-line-header {
  min-height: 25px;
}
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 40px;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid gray;
}
.search_input {
  padding: 0 10px;
  width: 450px;
  caret-color: red;
  transition: width 0.4s linear;
}

.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-decoration: none;
}
.border1 {
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #000000 !important;
  background-color: #000000 !important;
}
.bg-drive {
  background: rgb(28, 130, 129);
  background: transparent;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c3c3c3;
}
.small-box > .small-box-footer2 {
  position: relative;
  text-align: center;
  padding: 3px 2px;
  color: #000000;
  display: block;
  z-index: 10;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-item {
  padding: 0.25rem 1rem !important;
}

.icon-p {
  font-size: 1em !important;
  padding-right: 6px;
}

.ab {
  font-size: 1em !important;
  padding: 8px !important;
  cursor: pointer;
  margin: 2px !important;
}
.ab:hover {
  font-size: 1em !important;
  padding: 7px !important;
  cursor: pointer;
  background: #d2d1d1;
  border: 1px solid #d2d1d1;
  border-radius: 50px;
  margin: 2px;
}
.b-n {
  border: none !important;
}
.b-input {
  border: 1px solid rgb(206, 212, 218);
  border-radius: 5px;
  padding: 1px;
}

.p-h2 {
  font-weight: bold;
  font-size: 18px;
  padding-top: 6px;
  padding-left: 10px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #2dbe60 !important;
}
body .p-dataview .p-dataview-content {
  border: none !important;
  padding: 10px 2px !important;
}
.effect1 {
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 6px -6px #efeeee;
  -moz-box-shadow: 0 10px 6px -6px #efeeee;
  box-shadow: 0 10px 6px -6px #efeeee;
}
.navbar-dark .navbar-nav .nav-link {
  color: #ffffff !important;
}
.header-content {
  color: rgb(255, 255, 255);
  position: relative;
  min-height: 350px;
  background: url("../assets/login_header.png") no-repeat scroll 0px 100% /
    cover transparent;
}
.footer-content {
  color: rgb(255, 255, 255);
  position: relative;
  min-height: 350px;
  background: url("../assets/login_footer.png") no-repeat scroll 0px 100% /
    cover transparent;
}
.cust-input {
  background: #ffffff;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
}
.btn-social-icon {
  width: 50px;
  height: 50px;
  padding: 2px;
  margin: 5px 10px;
}

.template-demo {
  display: inline-block;
  width: 100%;
  text-align: center;
}
button span.layout-topbar-icon {
  font-size: 1.5em !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #008000 !important;
  font-size: 14px;
  font-weight: bold;
}
.theme_title {
  text-align: center;
  font-weight: bold;
  display: block;
}
.theme_p {
  text-align: center;
  display: block;
}
.edit_icon {
  font-size: 1em !important;
  padding: 2px 3px;
  color: #000000 !important;
}
.phonebg label {
  position: relative;
  width: 125px;
  height: 260px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 30px;
  border-bottom-width: 30px;
  border-radius: 15px;
}
.customcheck input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  float: right;
  margin-right: 15px;
}

.cus-1 input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  display: none !important;
}
input[type="checkbox"]:checked + label {
  background-color: #bfb;
  border-color: #008000;
}
.phonebg input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.inline-b {
  display: inline-block !important;
}
.btn10 {
  background: #166a8f;
  padding: 5px 8px;
  float: right;
  color: #ffffff;
  font-size: 12px;
  border-radius: 15px;
  border: 1px solid #166a8f;
}
.btn9 {
  background: #166a8f;
  padding: 5px 8px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 15px;
  border: 1px solid #166a8f;
}
.her {
  border-bottom: 1px solid #c4c0c0;
  padding-bottom: 6px;
  margin-bottom: 15px;
}
.hdevice-name {
  color: #ffffff;
  display: block;

  margin-bottom: -5px;
}
.theme-name {
  font-size: 11px;
  color: #ffffff;
}
.phonebg label {
  cursor: pointer;
}
body .p-fieldset .p-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #f4f4f4;
  font-weight: 600;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  font-size: 14px;
}
/* #checkboxes .p-checkbox .p-checkbox-box {
  display: none !important;
} */
#cuscheck label {
  cursor: pointer;
}
#checkboxes label {
  cursor: pointer;
}
#ebanledapplist label {
  cursor: pointer;
}
body #checkboxes .p-checkbox {
  /* display: inline-block; */
  vertical-align: top;
  margin: 0;
  width: 0px;
  height: 0px;
  float: right;
}
body #ebanledapplist .p-checkbox {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  width: 20px;
  height: 20px;
}
body #checkboxes .p-checkbox .p-checkbox-box .p-checkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
  font-weight: 800;
}
body #ebanledapplist .p-checkbox .p-checkbox-box .p-checkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
  font-weight: 800;
}
.p-highlight .p-checkbox-label {
  background: #d2d6d2 !important;
}
#checkboxes [aria-checked="true"] {
  margin-right: 5px;
  margin-top: 30px;
  background: #fff;
  color: #008000;
  border: none;
  float: right;
}
#checkboxes [aria-checked="false"] {
  margin-left: -60px;
  margin-top: 30px;
  display: none;
}

#ebanledapplist [aria-checked="false"] {
  margin-left: -60px;
  margin-top: 30px;
  display: none;
}
#ebanledapplist [aria-checked="true"] .app-icon {
  width: 220px;
  background: red;
}
#ebanledapplist .app-icon {
  width: 220px;
}
#ebanledapplist [aria-checked="true"] {
  margin-left: 190px;
  margin-top: -45px;
  background: #fff;
  color: #008000;
  border: none;
}
.cus-nav .nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #000000 !important;
  font-weight: bold;
}
.cus-nav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000 !important;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 3px solid #2dbe60;
  border-radius: 0px;
}
.cus-nav .nav-item {
  padding: 0px 30px;
}
.p-r {
  padding-right: 6px !important;
}
.p-l {
  padding-left: 6px !important;
}
.p-m {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
body .datatable-paginator-demo .p-paginator .p-dropdown {
  width: auto;
  height: auto;
}
.chart-title {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #a3a0a0;
  padding-bottom: 10px;
}
.p-left {
  padding-left: 15px;
}
.custom-a {
  padding: 0px 5px;
  color: #000000;
}
.custom-a:hover {
  color: #116fbf;
}
.cus-btn {
  margin: 5px 5px 30px;
  background: rgb(22, 111, 138) !important;
  border: none !important;
}
a:not([href]) {
  color: #000000 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
a:not([href]):hover {
  color: #116fbf !important;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "" !important;
  background-image: url(../assets/upload.png);
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 20px;
  padding-right: 20px;
  background-size: 20px 16px;
}
.app-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: #166a8f;
  padding: 5px;
  margin-top: 3px;
  margin-left: 5px;
}
.custom-d .pi-chevron-down:before {
  content: "\e902";
  font-size: 10px;
  font-weight: bold;
  color: #000;
}
.custom-d .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  top: 47%;
  left: 65%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  position: absolute;
}
.m-cur {
  cursor: pointer;
}
body .p-breadcrumb ul li .p-menuitem-icon {
  color: #ffffff !important;
}
.b-link .custom-a:hover {
  color: #fff !important;
  text-decoration: none;
}
.b-link .custom-a {
  text-decoration: none !important;
}
@media (min-width: 1200px) {
  .p-xl-4 {
    padding: 1rem !important;
  }
  [id="cus-padding"] .p-xl-3 {
    padding: 0.5rem !important;
  }
  [id="cus-padding"] .p-md-5 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-weight: bold;
  }
  [id="cus-p"] .p-lg-3 {
    padding: 1rem !important;
  }
  [id="custom-div"] .p-lg-4 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 768px) {
  [id="cus-padding"] .p-md-5 {
    padding: 0.5rem !important;
  }
}
@media (min-width: 1025px) {
  .layout-wrapper.layout-static .layout-main,
  .layout-wrapper.layout-static .layout-footer {
    margin-left: 270px !important;
  }
  .layout-wrapper.layout-static-sidebar-inactive .layout-main,
  .layout-wrapper.layout-static-sidebar-inactive .layout-footer {
    margin-left: 0px !important;
  }
  /* .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-footer,
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-main {
    margin-left: 0 !important;
  } */
}
.date-class {
  padding: 5px !important;
  color: #495057;
}
.lineup {
  width: 95%;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lineup2 {
  width: 95%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .p-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 2px;
}
body .p-paginator .p-dropdown {
  margin-left: 0.5em;
  height: auto !important;
  min-width: 20px;
  width: auto !important;
  min-width: 20px !important;
}
.cus-breadcum .p-breadcrumb {
  margin: 50px 0px 2px 0px !important;
}
body .p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}
body .p-overlaypanel .p-overlaypanel-close {
  background-color: transparent !important;
  width: 5px !important;
  height: 5px !important;
  line-height: 10px;
  top: 10px !important;
  right: 20px !important;
}
body .p-overlaypanel .p-overlaypanel-close:hover {
  background-color: transparent;
  color: #ffffff;
}
body .p-overlaypanel .p-overlaypanel-close > span {
  line-height: inherit;
  font-size: 1em !important;
}
.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none !important;
}
.helptip-title {
  background: rgb(45, 190, 96);
  color: rgb(255, 255, 255);
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.helptip-des {
  padding: 15px;
  text-align: justify;
  height: fit-content;
  max-height: 265px;
  overflow: auto;
}
body .p-overlaypanel {
  border-radius: 10px !important;
}
.custom-file-label {
  text-overflow: ellipsis;
  overflow: hidden;
}
.p-21 {
  padding: 8px 20px 12px 20px !important;
}
body .p-dialog .p-dialog-titlebar {
  border: 1px solid #ffffff !important;
  background-color: rgb(45 190 96) !important;
  color: #ffffff !important;
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.p-dialog .p-dialog-titlebar-icon span {
  color: #fff;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}
.react-confirm-alert-body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
  padding: 0px !important;
}
.react-confirm-alert-body > h1 {
  background: rgb(45 190 96);
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  font-size: 16px;
  padding: 10px;
  color: #fff;
}
.react-confirm-alert-button-group {
  display: block !important;
  padding: 5px;
  text-align: center;
  border-top: 1px solid #bbbbbb;
}
.mod-p {
  padding: 12px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: #d83232;
  font-weight: bold;
}
.mod-sp {
  padding: 12px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: #0f8e3c;
  font-weight: bold;
}
.react-confirm-alert-button-group > button {
  background: rgb(22, 106, 143) !important;
  padding: 8px 20px !important;
  border-radius: 9px !important;
  font-size: 13px !important;
}
.icon-p {
  cursor: pointer;
}
.p-button-label {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 5px;
}
.p-dialog-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #2dbe60 !important;
  padding: 10px !important;
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}
.p-dialog-title {
  list-style: 1;
  color: #ffffff !important;
  font-weight: bold !important;
}
.p-dialog .p-dialog-header-icon {
  color: #fff !important;
}
.icon-a {
  color: #000000 !important;
}
.cus-p-1 {
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.cus-t {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.cus-t span {
  background: #fff;
  padding: 0 10px;
  color: #000000;
}
.even-col {
  background: #ffffff !important;
}
.odd-col {
  background: #f2f2f2 !important;
}
body .p-treetable .p-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #ffffff !important;
  background-color: #24b055 !important;
  text-align: center;
}
.btn-main {
  background: rgb(22, 111, 138) !important;
  border: none !important;
}
.btn-main1 {
  background: transparent !important;
  border-color: rgb(22, 111, 138) !important;
  color: rgb(22, 111, 138) !important;
  padding: 5px 20px !important;
}
.btn-main1:hover {
  background: rgb(22, 111, 138) !important;
  border-color: rgb(22, 111, 138) !important;
  color: #ffffff !important;
}
.btn-suc {
  background: #28a745 !important;
  border-color: #28a745 !important;
}
.cus-pad {
  padding: 6px 30px !important;
}
.p-field-checkbox > label {
  margin-bottom: 0px !important;
}
.p-button-label {
  -ms-flex: 1 1 auto !important;
  flex: none !important;
  display: inline-block;
}
.p-button-icon-right {
  font-size: 1em !important;
}
.p-button-icon-left {
  font-size: 1em !important;
  padding-right: 2px;
}
body .p-togglebutton.p-highlight {
  background-color: rgb(22, 111, 138) !important;
  border-color: rgb(22, 111, 138) !important;
  color: #ffffff;
}
body .p-togglebutton {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  color: #ffffff !important;
}
.cus-p2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px !important;
  padding-left: 5px;
}
body .p-tree {
  max-height: 270px;
  overflow: auto;
}
body .p-button {
  border-radius: 20px !important;
}
body #search .p-button {
  border-radius: 0px !important;
}
.btn-primary {
  color: #fff;
  background-color: rgb(22, 111, 138) !important;
  border-color: rgb(22, 111, 138) !important;
  padding: 6px 30px !important;
  border-radius: 20px !important;
}
.btn-danger {
  padding: 6px 30px !important;
  border-radius: 20px !important;
}
body .p-togglebutton:focus {
  outline: none;
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: rgb(22, 111, 138) !important;
  color: #ffffff;
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  color: #ffffff;
  background-color: rgb(22, 111, 138) !important;
}
.s-icon {
  background: transparent !important;
  color: #000 !important;
  border: 1px solid #a6a6a6 !important;
  margin-left: -1px !important;
  padding-left: 12px !important;
}
body .p-button.p-button-icon-only {
  width: 2.6em;
}

.field {
  margin: 5px;
  display: flex;
  flex-direction: column;
}
/* input[type="file"] {
  display: none;
} */

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 3px;
  cursor: pointer;
  background: transparent;
  margin-bottom: 10px;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid gray;
  margin: auto;
}
.img-upload:before {
  content: url(../assets/photo.png);
  font-size: 90px;
  position: absolute;
  padding-top: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
  border: 2px solid gray;
}
.img-upload:hover:before {
  opacity: 1;
}
#user img {
  width: auto;
  height: 100%;
}

#user label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}

#user input {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}
#user input:focus {
  outline: none;
  border: 1px solid #64d488;
}
#user input::placeholder {
  color: #bebebe;
}
.gird-p {
  padding: 1rem !important;
}
.pop-box {
  display: inline-block;
  padding: 0px !important;
  margin-bottom: 5px;
  border: 1px solid #dbdde0;
  border-radius: 10px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.c-icon {
  position: absolute;
  margin-left: -24px;
  margin-top: 7px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
}
#download:hover .c-icon {
  opacity: 1;
}
.c-btn {
  top: 18%;
  margin-top: -40px;
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  background: #28a745;
  border: none;
  padding: 0px;
  color: #fff;
  border-radius: 10px;
  opacity: 0;
}
#download:hover .c-btn {
  opacity: 1;
}
.c-row {
  margin-right: -10px !important;
  margin-left: -10px !important;
}
.screenshots {
  height: 145px !important;
  width: 145px !important;
}
.w-p {
  text-align: center;
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.cus-row {
  background: #2282ad;
  padding: 10px;
  border-radius: 7px;
}
body .p-datatable .p-datatable-header {
  background-color: #ffffff !important;
  color: #333333;
  border: none !important;
}
.p-inputgroup .p-inputtext {
  color: #333333;
  background: #f7f5f5 !important;
  border: 1px solid #dbdde0 !important;
}

.s-btn {
  background: #f7f5f5 !important;
  border-left: none !important;
  border-bottom: 1px solid #dbdde0 !important;
  border-right: 1px solid #dbdde0 !important;
  border-top: 1px solid #dbdde0 !important;
}
.btn-primary2 {
  color: #fff !important;
  background-color: rgb(22, 111, 138) !important;
  border-color: rgb(22, 111, 138) !important;
  padding: 6px 15px !important;
  border-radius: 20px !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  font-weight: bold;
}
.f-1 {
  font-size: 1em !important;
}
.btn-primary5 {
  color: #fff !important;
  background-color: rgb(22, 111, 138) !important;
  border-color: rgb(22, 111, 138) !important;
  padding: 6px 20px !important;
  border-radius: 20px !important;
}
.date-class {
  padding: 5px !important;
  border: 1px solid #ced4da;
  height: calc(1.5em + 0.9rem + 3px);
}
.form-control {
  height: calc(1.7em + 0.8rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.c-p {
  padding: 28px 0px !important;
}
.app-title {
  width: 85%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
/* --- Pricing Plans --- */

.pricing-plans {
  padding: 2em 0;
  width: 100%;
}

.pricing-tables {
  display: flex;
  flex-flow: column;
  padding-top: 1em;
}

.pricing-plan {
  background-color: #f6f6f6;
  border: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  display: block;
  padding: 1em 0;
  text-align: center;
  width: 100%;
}

.pricing-plan:first-child {
  border-bottom: 0;
}

.pricing-plan:last-child {
  border-top: 0;
}

.pricing-plan:nth-child(2) {
  border-bottom: 0;
}

.no-flexbox .pricing-plan {
  float: left;
}

.plan-title {
  font-size: 1em;
  letter-spacing: -0.05em;
  margin: 0;
  padding: 0.75em 1em 1.25em;
  text-transform: uppercase;
}

.plan-cost {
  background-color: white;
  color: #77b9dd;
  font-size: 1.25em;
  font-weight: 700;
  padding: 1.25em 1em;
  text-transform: uppercase;
}

.plan-cost span {
  display: none;
}

.plan-price {
  font-size: 3em;
  letter-spacing: -0.05em;
  line-height: 1;
  margin-bottom: 0;
}

.plan-type {
  border: 0.313em solid #ddd;
  color: #999;
  display: inline-block;
  font-size: 0.75em;
  margin: 0.75em 0 0 0.75em;
  padding: 0.3em 0.4em 0.25em;
  width: auto;
}

.plan-features {
  margin: 0;
  padding: 2em 1em 1em;
  margin-bottom: 15px;
  text-align: left;
}

.plan-features li {
  list-style-type: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5em;
  padding-bottom: 0.75em;
  color: #0a0a0a;
  display: block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.3;
}

.plan-features li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.plan-features i {
  font-size: 1.5em;
}

.plan-features i.icon-ok-squared {
  color: #3aa14d;
}

.plan-features i.icon-cancel-circled {
  color: darkRed;
}

.btn-plan {
  color: white;
  max-width: 12em;
  padding: 8px 12px;
  border-radius: 4px;
}

.cta {
  background-color: #57910b;
  padding: 8px 12px;
  border-radius: 4px;
}

.featured-plan {
  background-color: #eef7fc;
  border-right: 0 solid transparent;
  border-left: 0 solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  transition: transform 400ms ease;
}

.featured-ribbon {
  width: 200px;
  background: #1b8dc8;
  position: absolute;
  top: 15px;
  left: -60px;
  text-align: center;
  line-height: 35px;
  letter-spacing: 0.01em;
  font-size: 0.65em;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  /* Custom styles */
  /* Different positions */
}

.featured-ribbon.sticky {
  position: fixed;
}

.featured-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.featured-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.featured-ribbon.top-right {
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.featured-ribbon.bottom-left {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.featured-ribbon.bottom-right {
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@media (min-width: 400px) {
  .pricing-plans {
    padding-right: 2em;
    padding-left: 2em;
    width: 100%;
  }

  .featured-plan {
    transform: scale(1.05);
  }
}

@media (min-width: 33.75em) {
  .pricing-plans .module-title {
    margin-bottom: 1em;
  }

  .pricing-tables {
    flex-flow: row wrap;
  }

  .pricing-plan {
    flex-grow: 1;
    width: 50%;
  }

  .pricing-plan:first-child {
    border-right: 0;
    border-bottom: 0;
  }

  .featured-plan {
    margin-top: 0.6em;
    order: 0;
  }

  .pricing-plan:last-child {
    border-top: 2px solid #ddd;
    border-left: 0;
  }

  .no-flexbox .pricing-plan {
    width: 48%;
  }

  .plan-title {
    font-size: 0.875em;
  }
}

@media (min-width: 48em) {
  .no-flexbox .pricing-plan {
    width: 24%;
  }

  .plan-type {
    font-size: 0.7em;
    margin: 0.5em 0 0 1em;
    padding-bottom: 0.2em;
  }

  .featured-ribbon {
    font-size: 0.65em;
  }
}

@media (min-width: 62em) {
  .pricing-tables {
    padding-top: 3em;
  }

  .pricing-plan {
    flex-grow: 1;
    width: 25%;
  }

  .featured-plan {
    margin-top: 0;
    order: 0;
  }

  .pricing-plan:first-child,
  .pricing-plan:nth-child(2n) {
    border-bottom: 2px solid #ddd;
  }

  .pricing-plan .plan-features span {
    display: block !important;
  }

  .plan-cost {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-size: 1em;
  }

  .plan-cost span {
    color: #bbb;
    font-size: 1.5em;
    font-weight: 400;
    padding-right: 0.15em;
    padding-left: 0.15em;
  }

  .plan-price {
    font-size: 3.25em;
  }

  .btn-plan {
    font-size: 0.875em;
  }

  .featured-ribbon {
    font-size: 0.45em;
    left: -68px;
    line-height: 25px;
  }
}

@media (min-width: 75em) {
  .plan-cost {
    font-size: 1em;
  }
}

@media (min-width: 100em) {
  .pricing-tables {
    margin: 0 auto;
    max-width: 75em;
  }
}
.pricing-plan .pricingTable-header {
  color: #fff;
  padding: 15px 30px 30px 30px;
  margin: -25px 0 25px;
  border-radius: 0 20px 0 0;
  box-shadow: 4px -4px 0 0 rgba(255, 255, 255, 0.5) inset,
    -5px 5px 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  margin-top: -40px;
}
.pricing-plan .pricingTable-header:before {
  content: "";
  background: linear-gradient(to right bottom, transparent 45%, #05878c 50%);
  height: 25px;
  width: 15px;
  position: absolute;
  left: -15px;
  top: 0;
}
.pricing-plan .title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 12px;
}
.pricing-plan .price-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.pricing-plan.blue .pricingTable-header,
.pricing-plan.blue .btn-plan {
  background-color: #ffa500;
}
.featured-plan.blue {
  border-bottom: 5px solid #ffa500;
}
.pricing-plan.blue .pricingTable-header:before {
  background: linear-gradient(to right bottom, transparent 45%, #ffa500 50%);
}
.pricing-plan.green .pricingTable-header,
.pricing-plan.green .btn-plan {
  background-color: #12bdc4;
}
.featured-plan.green {
  border-bottom: 5px solid #12bdc4;
}
.pricing-plan.green .pricingTable-header:before {
  background: linear-gradient(to right bottom, transparent 45%, #12bdc4 50%);
}
.pricing-plan.red .pricingTable-header,
.pricing-plan.red .btn-plan {
  background-color: #dc1414;
}
.featured-plan.red {
  border-bottom: 5px solid #dc1414;
}
.pricing-plan.red .pricingTable-header:before {
  background: linear-gradient(to right bottom, transparent 45%, #dc1414 50%);
}
.plan-features a:hover {
  color: #ffffff;
  text-decoration: none;
}

.right-check {
  font-weight: bold;
  color: #169843;
}
.cross-check {
  font-weight: bold;
  color: #dc1414;
}
.price h4 {
  overflow: hidden;
  font-size: 18px;
}

.price h4:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #333333;
}
.featured-plan .btn-plan {
  border: none;
  box-shadow: 0 0 0 0.2rem #dee2e6;
  padding: 11px 20px;
}
.edit-icon {
  background: #166f8a;
  padding: 10px;
  border-radius: 50px;
  font-size: 1em !important;
  color: #fff;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

/* just some content with arbitrary styles for explanation purposes */
.modal1 {
  width: 100%;
  height: 140px;
  line-height: 1;
  position: absolute;
  top: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 100;
  padding: 25px 15px;
  border: 10px solid #ff0202;
}
@media (max-width: 1024px) {
  .overlay1 {
    left: -250px;
    margin-top: 50px;
  }
  .layout-mobile-sidebar-active .layout-sidebar .overlay1 {
    left: -0px;
    margin-top: 50px;
  }
}
@media (min-width: 1025px) {
  .overlay1 {
    left: -0px;
  }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive
    .layout-sidebar
    .overlay1 {
    left: -250px;
    margin-top: 50px;
  }
}
.h-div {
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 10px;
}
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: #108f3d !important;
  border: 1px solid #019234 !important;
  color: #ffffff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.p-accordion-header-text {
  padding-left: 6px;
}
.cus-assign {
  margin-left: -10px;
  display: block;
}
.cus-assign:hover {
  background-color: #2dbe60 !important;
  margin-left: 0px;
}
.icon-a:hover {
  margin-left: -10px;
}
body #c-p .p-inputtext {
  color: #495057 !important;
  border: none !important;
  font-weight: normal;
  padding: 0.58em !important;
}
body #c-p .p-dropdown {
  border: 1px solid #ced4da !important;
}
#cus-from .form-control {
  height: calc(1.4em + 0.8rem + 2px) !important;
}
.modal-header {
  padding: 5px 10px;
  background: #2dbe60 !important;
  color: #fff;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
.close {
  color: #fff !important;
  opacity: 1 !important;
}
body .p-dropdown-panel {
  z-index: 9999 !important;
}
textarea.form-control {
  height: auto !important;
}
.btn-s {
  background-color: rgb(22, 111, 138) !important;
  padding: 5px 10px !important;
  border: 1px solid rgb(22, 111, 138) !important;
  color: #fff !important;
}
.p-inputswitch-slider:before {
  position: absolute;
  content: "";
  top: 15% !important;
}
.bodycontent img {
  font-size: 7px;
}
.p-component-overlay-leave {
  display: none !important;
}
.lose {
  color: red;
}
.win {
  color: green;
}
.gain {
  color: #2626d4;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}
#dd .p-dropdown-clear-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 15%;
}
